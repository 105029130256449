import React from 'react'

import livingRoom from '../asset/livingRoom.jpg'
import styles from './TitleSection.module.css'

const TitleSection = () => {
  return (
    <div className={styles['container']}>
      <img src={livingRoom} alt="QOREMedia" className={styles['coverImg']} />
      <div className={styles['titleTextContainer']}>
        <h1>QORE MEDIA</h1>
        <h3>REAL ESTATE | AUTOMOTIVE | FITNESS</h3>
      </div>
    </div>
  )
}

export default TitleSection