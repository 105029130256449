import React from 'react'

import styles from './GridSection.module.css'

import realEstate1 from '../asset/realEstate/realEstate1.jpg'
import realEstate2 from '../asset/realEstate/realEstate2.jpg'
import realEstate3 from '../asset/realEstate/realEstate3.jpg'
import automotive1 from '../asset/automotive/automotive1.jpg'
import automotive2 from '../asset/automotive/automotive2.jpg'
import automotive3 from '../asset/automotive/automotive3.jpg'
import fitness1 from '../asset/fitness/fitness1.jpg'
import fitness2 from '../asset/fitness/fitness2.jpg'

const GridSection = () => {
  return (
    <div className={styles['container']}>
        <div className={styles['realEstateColumn']}>
            <div className={styles['imgContainer']}>
                <img src={realEstate1} alt="Real Estate 1" className={styles['img']} />
            </div>
            <div className={styles['imgContainer']}>
                <img src={realEstate2} alt="Real Estate 2" className={styles['img']} />
            </div>
            <div className={styles['imgContainer']}>
                <img src={realEstate3} alt="Real Estate 3" className={styles['img']} />
            </div>
        </div>
        <div className={styles['automotiveColumn']}>
            <div className={styles['filler']}></div>
            <div className={styles['imgContainer']}>
                <img src={automotive1} alt="Automotive 1" className={styles['img']} />
            </div>
            <div className={styles['imgContainer']}>
                <img src={automotive2} alt="Automotive 2" className={styles['img']} />
            </div>
            <div className={styles['imgContainer']}>
                <img src={automotive3} alt="Automotive 3" className={styles['img']} />
            </div>
        </div>
        <div className={styles['fitnessColumn']}>
            <div className={styles['imgContainer']}>
                <img src={fitness1} alt="Fitness 1" className={styles['img']} />
            </div>
            <div className={styles['imgContainer']}>
                <img src={fitness2} alt="Fitness 2" className={styles['img']} />
            </div>
        </div>
    </div>
  )
}

export default GridSection