import React from 'react'
import NavBar from '../component/NavBar'
import TitleSection from '../component/TitleSection'
import GridSection from '../component/GridSection'

const MainPage = () => {
  return (
    <div>
        <NavBar />
        <TitleSection />
        <GridSection />
    </div>
  )
}

export default MainPage