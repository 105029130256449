import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentPage: "main",
}

const rootSlice = createSlice({
    name: 'root',
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        }
    }
})

export const { 
    setCurrentPage
} = rootSlice.actions;
export const getCurrentPage = state => state.root.currentPage;
export default rootSlice.reducer;