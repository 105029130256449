import React from 'react'

import styles from './NavBar.module.css'
import QoreLogo from '../asset/QoreLogo_BROWN.png'

const NavBar = () => {
return (
    <div className={styles['container']}>
            <div className={styles['menu-button']}>
                <div className={styles['line']}></div>
                <div className={styles['line']}></div>
                <div className={styles['line']}></div>
            </div>
            <div className={styles['logo']}>
                <img src={QoreLogo} alt="Qore Logo" className={styles['logo-img']} />
            </div>
            <div className={styles['info-detail']}>
                <div>
                    CONTACT@QORE.MEDIA
                </div>
                <div>
                    (778) 819 3975
                </div>
            </div>
    </div>
)
}

export default NavBar